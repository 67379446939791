.Cookies {
  padding: 0 5em;
  min-height: 60vh;
  background: #e9e9e9;

  .Content {
    text-align: left;
    padding: 1em 1em;

    table {
      table-layout: auto;

      td {
        word-wrap: break-spaces;
      }
    }

    p {
      margin: 0;
      padding: 0.5em 0;
    }

    h2, h3, h4 {
      padding: 0.5em 0;
    }
  }
}