.Allergies {
  padding: 0 5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60vh;
  background: #e9e9e9;

  .content {
    padding: 1em 0;

    h1 {
      color: red;
    }

    img {
      max-width: 100%;
    }
  }
}