.Home {
	min-height: 40vh;
	padding: 1em 2em;

	button {
		padding: 0.2em 0.5em;
	}

	a {
		font-style: italic;
		font-weight: 700;
		text-decoration: none;
		color: white;
	}

	@media only screen and (max-width: 768px) {
		min-height: 60vh;
		padding: 0.5em;
	}

	hr {
		color: black;
		margin-left: 5em;
		margin-right: 5em;
	}

	.logo {
		margin-left: 10em;
		margin-right: 10em;
		margin-bottom: 0.5em;

		img {
			max-width: 15vw;
			margin: 0.5em 2em;
		}

		@media only screen and (max-width: 768px) {
			margin-left: 5em;
			margin-right: 5em;
			margin-bottom: 0.5em;

			img {
				max-width: 20vw;
				margin: 0.5em 1.5em;
			}
		}
	}

	.content {
		display: flex;
		flex-direction: column;

		p {
			font-size: calc(12px + 1vmin);
			margin: 1em 1em;
		}

		img {
			max-width: 20vw;
		}

		.horario-title {
			margin: 2em 0.5em 0.5em;
			font-size: calc(10px + 1.5vmin);
		}

		.horario {
			margin: 0.2em 0.5em;
			font-size: calc(10px + 1vmin);
		}

		@media only screen and (max-width: 768px) {
			display: flex;
			flex-direction: column;

			img {
				max-width: 40vw;
			}

			p {
				font-size: calc(12px + 1vmin);
			}

			.horario-title {
				margin: 2em 0.5em 0.5em;
				font-size: calc(10px + 1.5vmin);
			}

			.horario {
				margin: 0;
				font-size: calc(10px + 1vmin);
			}
		}

		.col {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			min-height: 35vh;
			width: 50vw;

			@media only screen and (max-width: 768px) {
				width: 100vw;

				&:nth-child(even) {
					margin-top: 1.5em;
				}
			}

			.logo-container {
				margin: 0;
				padding: 0;
			}

			.links-container {
				margin: 0;
				padding: 0;
				display: block;
				align-items: center;
				text-align: center;
			}
		}
	}
}
