@import url('https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,500;0,600;0,700;1,500;1,600;1,700&display=swap');

body {
  min-height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
  min-height: 100vh;
  font-family: 'Muli', sans-serif;
  background: $light-gray;

  .btn {
    font-size: calc(12px + 1vmin);
  }

  .App-header {
    width: 100vw;
    min-height: 10vh;
    vertical-align: center;
    font-size: calc(12px + 1vmin);
    padding-bottom: 0.5em;
    background-color: rgb(220, 220, 220);

    h1 {
      font-size: calc(12px + 1vmin);
      padding-top: 0.8em;
    }

    img {
      max-width: 1.5em;
      padding-bottom: 0.2em;
    }

    a {
      margin: 0 0.8em;
    }

    p {
      margin: 0;
    }

    @media only screen and (max-width: 768px) {
      font-size: calc(12px + 1vmin);

      h1 {
        font-size: calc(12px + 1vmin);
      }
    }
  }

  .App-footer {
    display: flex;
    flex-direction: column;
    min-height: 10vh;
    width: 100vw;
    padding: 1em 1.2em 0 1.2em;
    background-color: rgb(220, 220, 220);

    .address {
      padding: 0.3em 0;

      span {
        font-size: calc(8px + 1vmin);
        font-weight: 700;
      }
    }

    span {
      color: black;
      text-decoration: none;
    }

    .social-media {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: calc(10px + 1vmin);

      a {
        &:first-child {
          margin-right: 1.2em;
        }
      }

      .social {
        margin-right: 0.5em;
        max-width: 1.8em;
      }
    }

    .links {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0;
      font-size: calc(10px + 1vmin);

      @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .divider {
          visibility: hidden;
        }
      }

      .divider {
        width: 1px;
        background: gray;
        margin: 0 0.5em;
      }

      .developed-by {
        font-size: calc(8px + 1vmin);

        @media only screen and (max-width: 768px) {
          margin-top: 0.4em;
        }
      }
    }
  }
}
